import { FC, useEffect, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { AddIcon, CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import classNames from "classnames";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { IAirlineInfo } from "../../app/services/home/home";
import { IAirLineDataInfo } from "../sections/CustomerAirline";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";

interface IData {
  activeLang: string;
  initialData: IAirLineDataInfo;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  changeLanguage: (code: string) => void;
}
interface IAirLine {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  link_en: string;
  link_zh: string;
  link_cn: string;
  image: File | undefined;
  image_url: string;
}
interface WhyChooseUsData {
  title: string;
  description: string;
}
const AirLine: FC<IData> = ({
  activeLang,
  setShowList,
  initialData,
  changeLanguage
}) => {
  const [message, setMessage] = useState<string>("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [data, setData] = useState<IAirLine>({
    id:0,
    title_en:'',
    title_tc:'',
    title_sc:'',
    link_en:'',
    link_zh:'',
    link_cn:'',
    image_url:'',
    image:undefined
  });
  const change_data = (value: any, key: string) => {
    if (data) {
      if (key == "image") {
        const url=value?URL.createObjectURL(value):'';
        setData((prev) => ({
          ...prev,
          image: value,
          image_url: url
        }));
        return;
      }
      setData({ ...data, [key]: value });
    }
  };
  const check_validation=()=>{
    if(data?.link_en && data?.link_zh && data?.link_cn && data?.image_url){
      return true;
    }
    return false;
  }
  const handle_submit=async()=>{
    setIsSave(true);
    // setIsRequired(true);
    // if(check_validation()==false){  
    //   setIsRequired(true);
    //   setIsSave(false);
    //   return;
    // }
    setIsRequired(false);
    const image:any=data?.image;
    const formData = new FormData();
    formData.append('id', data?.id?.toString()??0);
    formData.append('title_en', data?.title_en);
    formData.append('title_tc', data?.title_tc);
    formData.append('title_sc', data?.title_sc);
    formData.append('link_en', data?.link_en);
    formData.append('link_tc', data?.link_zh);
    formData.append('link_sc', data?.link_cn);
    formData.append('image', image);
    formData.append('type', image);
    const url=data?.id?'update-home-customer-airlines':'home-customer-airlines';
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          changeLanguage('en');
        }else{
          setErrorPopup(true);
          setMessage(Object.values(res?.data?.errors)?.join(' '));
        }
        setShowList(true);
        setIsSave(false);
      })
      .catch((err) => {
        console.log('error ', err);
        setIsSave(false);
        setErrorPopup(true);
        setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
      });
  }
  useEffect(()=>{
    if(initialData){
      setData({
        id:initialData?.id,
        title_en:initialData?.title_en,
        title_tc:initialData?.title_tc,
        title_sc:initialData?.title_sc,
        link_en:initialData?.link_en,
        link_zh:initialData?.link_tc,
        link_cn:initialData?.link_sc,
        image:undefined,
        image_url:initialData?.image
      })
    }
  },[initialData])
  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
    <CommonErrorPopup
      show={errorPopup}
      setShow={setErrorPopup}
      message={message}
      setMessage={setMessage}
    />
      <h2 className="text-lg text-black font-bold text-left my-5">Customers Airlines</h2>
      <div
        className={twJoin(
          activeLang == "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >

        <div>
          <div>
            <div className="grid grid-cols-1">
              <LabelAndTextbox
                label="Link (EN)"
                value={data?.link_en}
                setValue={(value: any) => change_data(value, 'link_en')}
              />
              {(isRequired==true && !data?.link_en)?<span className="text-12 text-red-500 font-normal">Link is required.</span>:null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>

          <div className="grid grid-cols-1">
            <LabelAndTextbox
              label="Link (TC)"
              value={data?.link_zh}
              setValue={(value: any) => change_data(value, 'link_zh')}
            />
            {(isRequired==true && !data?.link_zh)?<span className="text-12 text-red-500 font-normal">Link is required.</span>:null}
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>
          <div className="grid grid-cols-1">
            <LabelAndTextbox
              label="Link (SC)"
              value={data?.link_cn}
              setValue={(value: any) => change_data(value, 'link_cn')}
            />
            {(isRequired==true && !data?.link_cn)?<span className="text-12 text-red-500 font-normal">Link is required.</span>:null}
          </div>
        </div>
      </div>
      <div>
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(value, 'image')}
          label="Image"
          value={data?.image_url}
          setValue={(value: any) => change_data(value, 'image_url')}
        />
        {(isRequired==true && !data?.image_url)?<span className="text-12 text-red-500 font-normal">Image is required.</span>:null}
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
             isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            changeLanguage('en');
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default AirLine;

import { FC, useEffect, useState } from "react";
import Button from "../../components/common/Button";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import { useGetMissionInfoMutation, useGetMissionListQuery } from "../../app/services/company/visionmission";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import ContactUsLocationInput from "./ContactUsLocationInput";
import ContactUsLocationTable from "./ContactUsLocationTable";
import ContactUsLocationInputData from "./ContactUsLocationInputData";
import { IContactLocationData } from "./ContactUsLocationData";
import { useFetch_contact_dataQuery, useFetch_contact_data_infoMutation, useFetch_contact_listQuery } from "../../app/services/contact-us/contact-us";
import { ILabelValueData } from "../../latest-news/components/LatestNews";
import ContactUsLocationDataTable from "./ContactUsLocationDataTable";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import { NumberDropdownList } from "../../components/common/DropdownList";
export interface IContactLocation {
    id: number,
    map_en: string,
    map_tc: string,
    map_sc: string,
    name_en: string,
    name_tc: string,
    name_sc: string,
}
const ContactUsLocationDataList = ({
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IContactLocationData>({
        id: 0,
        label_en: '',
        label_tc: '',
        label_sc: '',
        text_en: '',
        text_tc: '',
        text_sc: '',
        url_en: '',
        url_tc: '',
        url_sc: '',
        location: '',
        icon: undefined,
        icon_url: ''
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [locationData, setLocationData] = useState<ILabelValueData[]>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const { data: locationList } = useFetch_contact_listQuery({
        page: 1,
        limit: 0,
        search: '',
        sort: sorting?sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','):'',
    })
    useEffect(() => {
        if (locationList) {
            if (locationList?.data) {
                setLocationData(locationList?.data?.map((loc: any) => {
                    return {
                        label: loc.name_en,
                        value: loc.id
                    }
                }))
            }
        }
    }, [locationList])
    const { data: missionList } = useFetch_contact_dataQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch,
        sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','),
    });
    const [getMissionInfo] = useFetch_contact_data_infoMutation();
    useEffect(() => {
        if (missionList) {
            setData(missionList.data);
            setItemCount(missionList.total);
        }
    }, [missionList]);
    const handle_submit = async (data: IContactLocationData) => {
        console.log("data ", data)
        const formData = new FormData();
        formData.set('id', data.id.toString() ?? 0);
        formData.set('label_en', data.label_en ?? '');
        formData.set('label_tc', data.label_tc ?? '');
        formData.set('label_sc', data.label_sc ?? '');
        formData.set('text_en', data.text_en ?? '');
        formData.set('text_tc', data.text_tc ?? '');
        formData.set('text_sc', data.text_sc ?? '');
        formData.set('url_en', data.url_en ?? '');
        formData.set('url_tc', data.url_tc ?? '');
        formData.set('url_sc', data.url_sc ?? '');
        formData.set('location_id', (data.location ? data.location : (locationData ? locationData[0]?.value : '')));
        if (data.icon)
            formData.set('icon', data.icon ?? '');
        const url = data?.id ? 'update-contact-data' : 'contact-data';
        await axios(endpointUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    getMissionInfo({
                        page: 1,
                        limit: 20,
                        search: '',
                        sort:''
                    }).then((res: any) => {
                        setData(res?.data?.data);
                        setItemCount(res?.data?.total);
                    })
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                    setShow(true);
                } else {
                    setErrorPopup(true);
                    setMessage(Object.values(res?.response?.data?.errors)?.join(' '));
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
            });
    }
    const clear_data = () => {
        setDetailData({
            id: 0,
            label_en: '',
            label_tc: '',
            label_sc: '',
            text_en: '',
            text_tc: '',
            text_sc: '',
            url_en: '',
            url_tc: '',
            url_sc: '',
            icon: undefined,
            icon_url: '',
            location: ''
        })
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
        />
        <div className="text-left">
            {show == false ? <ContactUsLocationInputData locationData={locationData} isSave={isSave} handle_submit={handle_submit} detailData={detailData} setDetailData={setDetailData} setShow={setShow} /> :
                <>
                    <div className="items-center xs:mt-1 sm:mt-1 flex justify-between mb-3">
                        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                                {setState =>
                                    <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                            onChange={e => setState(e.target.value)}
                                        />
                                        <SearchIcon className="mr-2" />
                                    </div>}
                            </DebounceSearchInput>
                        </div>
                        <Button
                            label="+ Add New"
                            onClick={() => { clear_data(); setShow(false) }}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <div className="flex sm:flex-row flex-col justify-end mb-1">
                        <div className="flex items-center sm:justify-start justify-end">
                            <div className="flex items-center 3xl:ml-3 mr-[10px]">
                                <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                                    DISPLAY
                                </p>
                                <NumberDropdownList
                                    state={pagination.pageSize}
                                    setState={value =>
                                        setPagination(prev => ({
                                            ...prev,
                                            pageSize: value as number
                                        }))}
                                    dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                                        const count = (index + 1) * 20;
                                        return {
                                            value: count,
                                            label: String(count)
                                        };
                                    })}
                                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                                    textAlign="text-left pl-3"
                                    paddingClass="py-[2px]"
                                    placeholder="All"
                                />
                            </div>
                        </div>
                    </div>
                    <ContactUsLocationDataTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                </>
            }
        </div>
    </div>
}
export default ContactUsLocationDataList;